import axios from 'axios'
import store from '@/store/index'

const axios_instance = axios.create({
    // baseURL: 'http://localhost:8081',
    // timeout: 5000,
})

axios_instance.interceptors.request.use(
    (request) => {
        // console.log(request)
        request.headers = {
            Authorization: `Bearer ${store.getters['auth_store/tokenValue']}`
        }
        return request
    },
    (error) => {
        // console.log(Promise.reject(error))
        // return Promise.reject(error)
        return error
    }
)

axios_instance.interceptors.response.use(
    (response) => {
        store.dispatch('clearOverlayMessage')
        return response
    },
    async (error) => {
        store.dispatch('clearOverlayMessage')
        let data = ''
        if (error.request.responseType === 'blob'){
            data = JSON.parse(await error.response.data.text())
        } else {
            data = error.response.data
        }

        store.dispatch('displayErrorMessage', {message: data.detail})
        return Promise.reject(error)
    }
)

export default axios_instance