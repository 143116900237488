import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/main/homeView.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  // main
  {
    path: "/face",
    name: "face",
    component: () => import('../views/main/face.vue')
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {requiresAuth: true}
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/main/loginView.vue')
  },
  {
    path: "/unofficialNoticeOrder",
    name: "unofficialNoticeOrder",
    component: () => import('../views/main/unofficialNoticeOrder.vue')
  },

  // master
  {
    path: '/headMasterArea',
    name: 'headMasterArea',
    component: () => import('../views/master/head/headMasterArea.vue')
  },
  {
    path: '/headMasterAssortment',
    name: 'headMasterAssortment',
    component: () => import('../views/master/head/headMasterAssortment.vue')
  },
  {
    path: "/headMasterBudget",
    name: "headMasterBudget",
    component: () => import('../views/master/head/headMasterBudget.vue')
  },
  {
    path: '/headMasterCategory',
    name: 'headMasterCategory',
    component: () => import('../views/master/head/headMasterCategory.vue')
  },
  {
    path: "/headMasterClass",
    name: "headMasterClass",
    component: () => import('../views/master/head/headMasterClass.vue')
  },
  {
    path: "/headMasterFixedOrderQuantity",
    name: "headMasterFixedOrderQuantity",
    component: () => import('../views/master/head/headMasterFixedOrderQuantity.vue')
  },
  {
    path: "/headMasterFixedProductItems",
    name: "headMasterFixedProductItems",
    component: () => import('../views/master/head/headMasterFixedProductItems.vue')
  },
  {
    path: '/headMasterItem',
    name: 'headMasterItem',
    component: () => import('../views/master/head/headMasterItem.vue')
  },
  {
    path: "/headMasterItemRegister",
    name: "headMasterItemRegister",
    component: () => import('../views/master/head/headMasterItemRegister.vue')
  },
  {
    path: "/headMasterItemsCallCodePrice",
    name: "headMasterItemsCallCodePrice",
    component: () => import('../views/master/head/headMasterItemsCallCodePrice.vue')
  },
  {
    path: "/headMasterLossRatio",
    name: "headMasterLossRatio",
    component: () => import('../views/master/head/headMasterLossRatio.vue')
  },
  {
    path: "/headMasterMaterialsConversionNormal",
    name: "headMasterMaterialsConversionNormal",
    component: () => import('../views/master/head/headMasterMaterialsConversionNormal.vue')
  },
  {
    path: "/headMasterMaterialsConversionRegister",
    name: "headMasterMaterialsConversionRegister",
    component: () => import('../views/master/head/headMasterMaterialsConversionRegister.vue')
  },
  {
    path: "/headMasterMaterialsConversionReverseResolution",
    name: "headMasterMaterialsConversionReverseResolution",
    component: () => import('../views/master/head/headMasterMaterialsConversionReverseResolution.vue')
  },
  {
    path: "/headMasterPeriods",
    name: "headMasterPeriods",
    component: () => import('../views/master/head/headMasterPeriods.vue')
  },
  {
    path: "/headMasterProductionTimeSlot",
    name: "headMasterProductionTimeSlot",
    component: () => import('../views/master/head/headMasterProductionTimeSlot.vue')
  },
  {
    path: "/headMasterSalesPromotion",
    name: "headMasterSalesPromotion",
    component: () => import('../views/master/head/headMasterSalesPromotion.vue')
  },
  {
    path: "/headMasterStoreGroups",
    name: "headMasterStoreGroups",
    component: () => import('../views/master/head/headMasterStoreGroups.vue')
  },
  {
    path: '/headMasterStoreHolidays',
    name: 'headMasterStoreHolidays',
    component: () => import('../views/master/head/headMasterStoreHolidays.vue')
  },
  {
    path: "/headMasterStores",
    name: "headMasterStores",
    component: () => import('../views/master/head/headMasterStores.vue')
  },
  {
    path: "/headMasterStoresRegister",
    name: "headMasterStoresRegister",
    component: () => import('../views/master/head/headMasterStoresRegister.vue')
  },
  {
    path: "/storeMasterBudget",
    name: "storeMasterBudget",
    component: () => import('../views/master/store/storeMasterBudget.vue')
  },
  {
    path: "/storeMasterLossRatio",
    name: "storeMasterLossRatio",
    component: () => import('../views/master/store/storeMasterLossRatio.vue')
  },
  {
    path: "/storeMasterStoreHolidays",
    name: "storeMasterStoreHolidays",
    component: () => import('../views/master/store/storeMasterStoreHolidays.vue')
  },
  {
    path: "/masterUsers",
    name: "masterUsers",
    component: () => import('../views/master/masterUsers.vue')
  },
  {
    path: "/masterUsersRegister",
    name: "masterUsersRegister",
    component: () => import('../views/master/masterUsersRegister.vue')
  },

  // productionPlanning
  {
    path: "/materialOrder",
    name: "materialOrder",
    component: () => import('../views/productionPlanning/materialOrder.vue')
  },
  {
    path: '/productionPlanning',
    name: 'productionPlanning',
    component: () => import('../views/productionPlanning/productionPlanning.vue')
  },
  {
    path: "/productionPlanningDetail",
    name: "productionPlanningDetail",
    component: () => import('../views/productionPlanning/productionPlanningDetail.vue')
  },

  // productionStandard
  {
    path: "/productionStandardData",
    name: "productionStandardData",
    component: () => import('../views/productionStandard/productionStandardData.vue')
  },
  {
    path: "/productionStandardDataRegister",
    name: "productionStandardDataRegister",
    component: () => import('../views/productionStandard/productionStandardDataRegister.vue')
  },

  // totalInquiry
  {
    path: "/totalInquiryMaterialAggregationInput",
    name: "totalInquiryMaterialAggregationInput",
    component: () => import('../views/totalInquiry/totalInquiryMaterialAggregationInput.vue')
  },
  {
    path: "/totalInquiryMaterialAggregationPeriods",
    name: "totalInquiryMaterialAggregationPeriods",
    component: () => import('../views/totalInquiry/totalInquiryMaterialAggregationPeriods.vue')
  },
  {
    path: "/totalInquiryMaterialAggregationWeekly",
    name: "totalInquiryMaterialAggregationWeekly",
    component: () => import('../views/totalInquiry/totalInquiryMaterialAggregationWeekly.vue')
  },
  {
    path: "/totalInquiryProductionPlanningAmPm",
    name: "totalInquiryProductionPlanningAmPm",
    component: () => import('../views/totalInquiry/totalInquiryProductionPlanningAmPm.vue')
  },
  {
    path: "/totalInquiryProductionPlanningTotalItems",
    name: "totalInquiryProductionPlanningTotalItems",
    component: () => import('../views/totalInquiry/totalInquiryProductionPlanningTotalItems.vue')
  },
  {
    path: "/totalInquiryProductionPlanningTotalStore",
    name: "totalInquiryProductionPlanningTotalStore",
    component: () => import('../views/totalInquiry/totalInquiryProductionPlanningTotalStore.vue')
  },
]

const router = new VueRouter({
  mode: "history",
  routes
})

history.pushState(null, '', null)

router.beforeEach((to, _, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  if (authRequired && !store.state.auth_store.token) {
    store.dispatch('auth_store/setReturnUrl', {returnUrl: to.fullPath})
    next({path: '/login', query: {redirect: to.fullPath}})
  } else {
    next()
  }
})

router.afterEach(() => {
  window.history.pushState(null, '', null)
})

export default router
