<template>
  <v-app>

    <v-navigation-drawer
    app
    v-model="drawer"
    clipped
    class="pl-0"
    v-if="isLogin"
    v-cloak
    :width="isLowResolution_ClientWidth ? 195 : 256"
    >
      <!-- リスト -->
      <v-container class="pr-1">
        <v-select
        v-if="storeNum > 1"
        v-model="store_code"
        hide-details=""
        :items="user_info.store_list"
        item-text="store_name"
        item-value="store_code"
        dense
        outlined
        label="店舗切替"
        @change="changeStore"
        >
        </v-select>

        <v-text-field
        v-else-if="storeNum === 1"
        v-model="display_title_name"
        hide-details=""
        label="店舗切替"
        dense
        readonly
        class="py-2"
        >
        </v-text-field>
        

        <v-list-item
        nav
        dense
        to="/"
        class="menu-item-home"
        >
          <v-list-item-title class="text-left">ホーム</v-list-item-title>
        </v-list-item>
        
        <v-divider></v-divider>

        <v-list nav dense>
          <template v-for="menu_item in menu">
            <v-list-item v-if="'link' in menu_item" :key="menu_item.name" :to="menu_item.link">
              <v-list-item-content>
                <v-list-item-title class="text-left">
                  {{menu_item.name}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group v-else :key="menu_item.name" no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{menu_item.name}}
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <template v-for="sub_menu_item in menu_item.list">
                <v-list-item v-if="'link' in sub_menu_item" :key="sub_menu_item.name" :to="sub_menu_item.link" class="sub-menu-item-link">
                  <v-list-item-content>
                    <v-list-item-title class="text-left">
                      {{sub_menu_item.name}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-group v-else :key="sub_menu_item.name" no-action sub-group>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{sub_menu_item.name}}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <template v-for="sub_sub_menu_item in sub_menu_item.list">
                    <v-list-item v-if="'link' in sub_sub_menu_item" :key="sub_sub_menu_item.name" :to="sub_sub_menu_item.link" class="sub-sub-menu-item-link">
                      <v-list-item-content>
                        <v-list-item-title class="text-left">
                          {{sub_sub_menu_item.name}}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list-group>
              </template>
            </v-list-group>
          </template>
        </v-list>
      </v-container>
    </v-navigation-drawer>

    <v-app-bar
    id="app-bar"
    app
    color="white"
    dark
    clipped-left
    v-if="isLogin"
    v-cloak
    class="app-bar"
    > 
      
      <v-app-bar-nav-icon @click="drawer =!drawer" class color="black"></v-app-bar-nav-icon>

      <v-toolbar-title class="top-title">{{display_connect_title}}（{{display_title_name}}）</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-row>
        <v-col cols="6" class="ma-0 pa-0"></v-col>
        <v-col cols="6" class="ma-0 pa-0">
          <v-row class="ma-0 pa-0 justify-end">
            <v-text-field 
            class="input-color-grey-class ma-0 pa-0"
            v-model="user_info.user_code"
            readonly
            hide-details=""
            dense
            >
            </v-text-field>
            <v-text-field 
            class="input-color-grey-class ma-0 pa-0"
            v-model="user_info.user_name"
            readonly
            hide-details=""
            dense
            >
            </v-text-field>
          </v-row>
          <v-row class="ma-0 pa-0 justify-end">
            <LogoutView/>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main id="main">
      <router-view></router-view>
    </v-main>


    <v-overlay absolute :value="time_overlay" :z-index="10000">
      <v-row align="center" justify="center">
      <div class="overmargin">発注締切時刻になりました。終了してください。</div>
      </v-row>
      <v-row align="center" justify="center">
      <span><v-btn color="primary"  min-width="112" @click="time_overlay = false" class="mx-1">
        閉じる
      </v-btn></span>
      </v-row>
    </v-overlay>

    <v-overlay absolute :value="paste_overlay" :z-index="10000">
      <v-row align="center" justify="center">
      <div class="overmargin">データ貼り付け処理中</div>
      </v-row>
      <v-row align="center" justify="center">
      <span><img src="@/assets/load.gif" /></span>
      </v-row>
    </v-overlay>

    <v-overlay absolute :value="pasteNG_overlay" :z-index="10000">
      <v-row align="center" justify="center">
      <div class="overmargin">貼り付けできないデータがあります。確認してください。</div>
      </v-row>
      <v-row align="center" justify="center">
      <span><v-btn color="primary"  min-width="112" @click="pasteNG_overlay = false" class="mx-1">
        閉じる
      </v-btn></span>
      </v-row>
    </v-overlay>

    <v-overlay absolute :value="excel_overlay" :z-index="10000">
      <v-row align="center" justify="center">
      <div class="overmargin">データ取得中</div>
      </v-row>
      <v-row align="center" justify="center">
      <span><img src="@/assets/load.gif" /></span>
      </v-row>
    </v-overlay>
    
    <v-overlay
    :value="fast_overlay"
    :z-index="300"
    >
      <v-card
      color="primary"
      dark
      >
        <v-card-text>
          <v-progress-circular
          indeterminate
          >
          </v-progress-circular>
          {{ '読み込み中' }}
        </v-card-text>
      </v-card>
    </v-overlay>

    <v-overlay
    :value="overlay"
    :z-index="300"
    >
      <v-card
      color="primary"
      dark
      >
        <v-card-text>
          <v-progress-circular
          indeterminate
          >
          </v-progress-circular>
          {{ overlay_message }}
        </v-card-text>
      </v-card>
    </v-overlay>

    <v-row justify="space-around" class="ma-0 pa-0">
      <v-col cols="auto"  class="ma-0 pa-0">
        <v-dialog
        :value="error_dialog"
        transition="dialog-top-transition"
        max-width="600"
        content-class="message-dialog"
        persistent
        >
          <template v-slot:default>
            <v-card>
              <v-card-text>
                <div class="pa-12">
                  <p v-html="error_message"></p>
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                text
                @click="close_error_dialog"
                >Close</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        
        </v-dialog>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import LogoutView from '@/views/main/logoutView.vue'
import MENU_LIST from '@/const/menu_const'
import axios from '@/axios/axios'



export default {
  name: 'App',

  components:{
    LogoutView,
  },

  data(){
    return{
      drawer:null,
      fast_overlay:true,
      time_overlay:false,
      paste_overlay:false,
      pasteNG_overlay:false,
      excel_overlay:false,
      display_connect_title:'',
      vendorFlg:true,
      cognito_id:'',
      cognito_uri:'',
      cognito_vendor_id:'',
      cognito_vendor_uri:'',
      cognito_callback_uri:'',
      cognito_callback_vendor_uri:'',
    }
  },

  mounted() {
    this.clearIndicator()
    this.fast_overlay = false
    window.addEventListener('popstate', () => history.pushState(null, '', null))
    this.titleConnect()
    
    this.getParameterByHash('id_token')
  },

  beforeDestroy() {
    window.removeEventListener('popstate', () => history.pushState(null, '', null))
  },

  computed: {
    isLogin() {
      return !!this.$store.getters['auth_store/tokenValue'] && this.$route.path !== '/login'
    },

    overlay() {
      return this.$store.getters.overlay
    },

    overlay_message() {
      return this.$store.state.overlay_message
    },

    overlay_pattern() {
      return this.$store.state.overlay_pattern
    },

    drawer_status() {
      return this.$store.state.drawer_status
    },

    error_dialog() {
      return this.$store.getters.error_dialog
    },

    error_message() {
      return this.$store.state.error_message
    },

    storeNum() {
      if (!this.user_info.store_list) {
        return 0
      }
      return this.user_info.store_list.length
    },

    user_info() {
      return this.$store.state.user_store
    },

    store_code: {
      get() {
        return this.$store.state.store_code
      },
      set(value) {
        this.$store.commit('setStoreCode', value)
      }
    },

    menu() {
      let menu = null
      switch (this.user_info.user_division) {
        case '1':
          menu = MENU_LIST.HEAD_MENU
          break
        case '2':
          menu = MENU_LIST.STORE_MENU
          break
        case '3':
          menu = MENU_LIST.VENDOR_MENU
          break
      }
      return menu
    },
    
    display_title_name() {
      let title_name = ''
      switch(this.user_info.user_division) {
        case '1': {
          title_name = '本部'
          break
        }
        case '2': {
          const store_code = this.store_code ? this.store_code : this.user_info.store_list[0].store_code
          const store = this.user_info.store_list.find(store => store.store_code === store_code)
          title_name = store.store_name
          this.$store.commit('setStoreCode', store_code)
          break
        }
        case '3': {
          title_name = 'ベンダー'
          break
        }
      }
      return title_name
    },
  },

  methods: {
    changeStore() {
      this.$router.push('/', () => {})
    },
    
    close_error_dialog() {
      this.$store.dispatch('clearErrorMessage')
    },
    
    async titleConnect(){
      let system_id = {};

      system_id = {
        params: { "system_id" : 'system_name'
        }
      }
      await axios.get('/request/common/m_system_management/get', system_id).then((response) => {
        this.display_connect_title = response.data.result.system_data
      })
    },

    async cognitoIdConnect(){
      let system_id = {};

      system_id = {
        params: { "system_id" : 'cognito_id'
        }
      }
      await axios.get('/request/common/m_system_management/get', system_id).then((response) => {
        this.cognito_id = response.data.result.system_data
      })
    },

    async cognitoUriConnect(){
      let system_id = {};

      system_id = {
        params: { "system_id" : 'cognito_uri'
        }
      }
      await axios.get('/request/common/m_system_management/get', system_id).then((response) => {
        this.cognito_uri = response.data.result.system_data
      })
    },

    async cognitoVendorUriConnect(){
      let system_id = {};

      system_id = {
        params: { "system_id" : 'cognito_vendor_uri'
        }
      }
      await axios.get('/request/common/m_system_management/get', system_id).then((response) => {
        this.cognito_vendor_uri = response.data.result.system_data
      })
    },

    async cognitoVendorIdConnect(){
      let system_id = {};

      system_id = {
        params: { "system_id" : 'cognito_vendor_id'
        }
      }
      await axios.get('/request/common/m_system_management/get', system_id).then((response) => {
        this.cognito_vendor_id = response.data.result.system_data
      })
    },
    async cognitoCallbackUriConnect(){
      let system_id = {};

      system_id = {
        params: { "system_id" : 'cognito_callback_uri'
        }
      }
      await axios.get('/request/common/m_system_management/get', system_id).then((response) => {
        this.cognito_callback_uri = response.data.result.system_data
      })
    },
    async cognitoCallbackVendorUriConnect(){
      let system_id = {};

      system_id = {
        params: { "system_id" : 'cognito_callback_vendor_uri'
        }
      }
      await axios.get('/request/common/m_system_management/get', system_id).then((response) => {
        this.cognito_callback_vendor_uri = response.data.result.system_data
      })
    },
    

  async do_post(url,body) {
      
      const headers = new Headers({
        "Content-Type": "application/x-www-form-urlencoded"
      });

      return await fetch(url, {
          method:'POST',
          headers:headers,
          body:body,
      })
      .then((response) => {
        //console.log(body)
        console.log(response)
          return response.json()
      });
  },

  async do_postToken(token) {
    var fetchURI
    var bearer = 'Bearer ' + token.access_token
    const headers = new Headers({
        "Authorization": bearer
      });
    if(this.vendorFlg == true){
      //ベンダー
      fetchURI = this.cognito_vendor_uri + 'oauth2/userInfo'
    }else{
      //レギュラー
      fetchURI = this.cognito_uri + 'oauth2/userInfo'
    }
    return await fetch(fetchURI, {
          method:'GET',
          headers:headers,
          
      })
    .then(async result =>{

        if (!result.ok) {
          console.log("userinfo_call Error:", result.status, result.statusText);
          return;
        }
        
        console.log("userinfo_call OK");
        var userText = await result.json();
        //console.log(userText.email)
        const auth_params = {
            username: userText.email,
            password: '1'
        }
        //取得したメールアドレスを使ってログイン処理
        return this.$store.dispatch('auth_store/login', auth_params)
        .catch(error => {
            this.error_message = error.response.data.detail
        })


      
    })
    .catch(error=>{
        console.log('userinfo_call Error:' + error)
    });
    
  },

  async getParameterByHash(){
    //認可コード取得
    let wHash = window.location.href;
    console.log(wHash.substring(wHash.indexOf('code%3D')+'code%3D'.length))
    wHash = wHash.substring(wHash.indexOf('code%3D')+'code%3D'.length)
    //20240111本番用パラメータ取得追加
    if(wHash.length > 50){
      const sURL = window.location.href;
      const searchParams = new URL(sURL).searchParams;
      wHash = searchParams.get('code')
      
      console.log(wHash)
    }

    await this.cognitoIdConnect()
    await this.cognitoUriConnect()
    await this.cognitoVendorIdConnect()
    await this.cognitoVendorUriConnect()
    await this.cognitoCallbackUriConnect()
    await this.cognitoCallbackVendorUriConnect()
    /*
    console.log(this.cognito_id)
    console.log(this.cognito_uri)
    console.log(this.cognito_vendor_id)
    console.log(this.cognito_vendor_uri)
    console.log(this.cognito_callback_uri)
    */
   
    //var ref = document.referrer;
    var ref = window.location.href;
    var postURL
    var param 
    if(ref.indexOf('vendor') !== -1){
      //ベンダー
      this.vendorFlg = true
      //認可コードを使ってトークンを取得するURIを作成
      param = 'grant_type=authorization_code&client_id='+ this.cognito_vendor_id +'&code=' + wHash + '&redirect_uri=' + this.cognito_callback_vendor_uri
      postURL = this.cognito_vendor_uri + "oauth2/token"
    } else{
      //レギュラー
      this.vendorFlg = false
      //認可コードを使ってトークンを取得するURIを作成
      param = 'grant_type=authorization_code&client_id=' + this.cognito_id + '&code=' + wHash + '&redirect_uri=' + this.cognito_callback_uri
      postURL = this.cognito_uri + "oauth2/token"
    }

    console.log(this.vendorFlg)
    return this.do_post(postURL,param)
    .then(result =>{
        if( result.error ){
            console.log('token_call Error:' + result.error)   
            return;
        }
        this.do_postToken(result)
    })
    .catch(error=>{
        console.log('catsh_token_call Error:' + error)
    });

    
  },


  },
  watch: {
    overlay_pattern(value){
      if(value == 1){
        this.time_overlay = true
        this.displayOverLay(0)
      }
      if(value == 2){
        this.paste_overlay = true
        
      }
      if(value == 2.1){
        this.paste_overlay = false
      }
      if(value == 2.2){
        this.pasteNG_overlay = true
        this.displayOverLay(0)
      }
      //20230926#1ファイル読み込みローディング、excel_overlay追加
      if(value == 3){
        this.excel_overlay = true
      }
      if(value == 3.1){
        this.excel_overlay = false
      }
    },
    drawer(value){
      this.drawerStatus(value)
    },
  },
};
</script>
<style scoped>
  .input-color-grey-class.v-text-field :deep() input{
    color: #9E9E9E;
    text-align: right;
    width: 120px;
  }

  :deep() .message-dialog {
    align-self: flex-start;
  }

  .overmargin {
    
    margin: 50px;
  }
</style>