import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth_store from './modules/auth-store'
import user_store from './modules/user-store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth_store,
    user_store
  },
  state: {
    storeCode:'000024',
    storeName:'二十四軒店',
    startDate:'',
    endDate:'',
    proDate:"2022/04/18(月)",
    comment:"〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇",
    category:"米飯",

    store_code: '',

    overlay_message: '',
    error_message: '',
    drawer_status: '',
    overlay_pattern: ''
  },
  getters: {
    storeCode (state, getters) {
      return getters.isHead ? '' : state.store_code
    },

    isHead (state) {
      return state.user_store.user_division === '1'
    },

    overlay (state) {
      return !!state.overlay_message
    },

    error_dialog (state) {
      return !!state.error_message
    }
  },
  mutations: {
    setStartDate (state, startDate) {
      state.startDate = startDate
    },
    setEndtDate (state, endDate) {
      state.endDate = endDate
    },

    setStoreCode (state, store_code) {
      state.store_code = store_code
    },

    setOverlayPattern (state, overlay_pattern) {
      state.overlay_pattern = overlay_pattern
    },

    setOverlayMessage (state, overlay_message) {
      state.overlay_message = overlay_message
    },

    setDrawerStatus(state, drawer_status) {
      state.drawer_status = drawer_status
    },


    setErrorMessage (state, error_message) {
      state.error_message = error_message
    }
  },
  actions: {
    clearStoreCode ({ commit }) {
      commit('setStoreCode', '')
    },

    displayOverlayPattern ({ commit }, {message: message}) {
      commit('setOverlayPattern', message)
    },

    displayOverlayMessage ({ commit }, {message: message}) {
      commit('setOverlayMessage', message)
    },

    displayDrawerStatus ({ commit }, {message: message}) {
      commit('setDrawerStatus', message)
    },

    clearOverlayMessage ({ commit }) {
      commit('setOverlayMessage', '')
    },

    displayErrorMessage ({ commit }, {message: message}) {
      commit('setErrorMessage', message)
    },

    clearErrorMessage ({ commit }) {
      commit('setErrorMessage', '')
    }
  },
  plugins: [createPersistedState(
    { // ストレージのキーを指定
      key: 'delica',
      // ストレージの種類を指定
      storage: window.sessionStorage
    }
  )]

})
