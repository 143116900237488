<template>
  <v-container fluid>
    <v-breadcrumbs :items="items" divider=">" class="py-0 pl-2"></v-breadcrumbs>
    <v-divider></v-divider>
  </v-container>
</template>

<script>
export default {
  name: 'homeView',
  components: {
  },
  data(){
    return{
        drawer:null,
        items:[
            {text:'ホーム', disabled: true, href: '/'}
        ],

        userCode:"1234567890",
        userName:"〇〇〇〇〇〇〇"
    }
  }
}
</script>
