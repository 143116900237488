import axios from '@/axios/axios'

const GET_USER = 'GET_USER'
const CLEAR_USER = 'CLEAR_USER'

const state = () => ({
    user_code: null,
    user_id: null,
    user_name: null,
    user_division: null,
    area_code: null,
    user_authority: null,
    vendor_code: null,
    vendor_name: null,
    note: null,
    store_list: []
})

const getters = {}

const actions = {
    getUser({ commit, rootGetters}) {
        let config = {
            headers: {
                Authorization: `Bearer ${rootGetters['auth_store/tokenValue']}`
            }
        }
        axios.get('/users/me', config)
        .then((response) => {
            commit(GET_USER, response.data)
        })
        .catch(error => {
            throw error
        })
    },

    clearUser({ commit }) {
        commit(CLEAR_USER)
    }
}

const mutations = {
    [GET_USER] (state, user) {
        state.user_code = user.user_code
        state.user_id = user.user_id
        state.user_name = user.user_name
        state.user_division = user.user_division
        state.area_code = user.area_code
        state.user_authority = user.user_authority
        state.vendor_code = user.vendor_code
        state.vendor_name = user.vendor_name
        state.note = user.note
        state.store_list = user.store_list
    },

    [CLEAR_USER] (state) {
        state.user_code = null
        state.user_id = null
        state.user_name = null
        state.user_division = null
        state.area_code = null
        state.user_authority = null
        state.vendor_code = null
        state.vendor_name = null
        state.note = null
        state.store_list = []
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}