import { render, staticRenderFns } from "./logoutView.vue?vue&type=template&id=398997c1&"
import script from "./logoutView.vue?vue&type=script&lang=js&"
export * from "./logoutView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports