const MENU_LIST = {
    VENDOR_MENU: [
        {
            name:	'発注状況'
        ,	link:	'/unofficialNoticeOrder'
        }
    ]
    
,   HEAD_MENU: [
        {
            name:	'生産基礎データ'
        ,	link:	'/productionStandardData'
        }
    ,	{
            name:	'マスター'
        ,	list:	[
                        {
                            name:	'商品マスター'
                        ,	link:	'/headMasterItem'
                        }
                    ,	{
                            name:	'カテゴリーマスター'
                        ,	link:	'/headMasterCategory'
                        }
                    ,	{
                            name:	'部材換算マスター'
                        ,	list:	[
                                        {
                                            name:	'通常'
                                        ,	link:	'/headMasterMaterialsConversionNormal'
                                        }
                                    ,	{	
                                            name:	'逆引き'
                                        ,	link:	'/headMasterMaterialsConversionReverseResolution'
                                        }
                                    ]
                        }
                    ,	{
                            name:	'店舗マスター'
                        ,	link:	'/headMasterStores'
                        }
                    ,	{
                            name:	'地区マスター'
                        ,	link:	'/headMasterArea'
                        }
                    ,	{
                            name:	'店舗グループマスター'
                        ,	link:	'/headMasterStoreGroups'
                        }
                    ,	{
                            name:	'販促区分マスター'
                        ,	link:	'/headMasterSalesPromotion'}
                    ,	{
                            name:	'製造時間帯マスター'
                        ,	link:	'/headMasterProductionTimeSlot'
                        }
                    ,	{
                            name:	'定量発注マスター'
                        ,	link:	'/headMasterFixedOrderQuantity'
                        }
                    ,	{
                            name:	'呼出コード・売価メンテ'
                        ,	link:	'/headMasterItemsCallCodePrice'
                        }
                    ,	{
                            name:	'月期マスター'
                        ,	link:	'/headMasterPeriods'
                        }
                    ,	{
                            name:	'品揃マスター'
                        ,	link:	'/headMasterAssortment'
                        }
                    ,	{
                            name:	'分類マスター'
                        ,	link:	'/headMasterClass'}
                    ,	{
                            name:	'定量生産マスター'
                        ,	link:	'/headMasterFixedProductItems'
                        }
                    ,	{
                            name:	'店休日マスター'
                        ,	link:	'/headMasterStoreHolidays'
                        }
                    ,	{
                            name:	'予算マスター'
                        ,	link:	'/headMasterBudget'
                        }
                    ,	{
                            name:	'ロス率マスター'
                        ,	link:	'/headMasterLossRatio'
                        }
                    ,	{
                            name:	'ユーザーマスター'
                        ,	link:	'/masterUsers'
                        }
                    ]
        }
    ,	{
            name:	'集計/照会'
        ,	list:	[
                        {
                            name:	'原料集約（入力）'
                        ,	link:	'/totalInquiryMaterialAggregationInput'
                        }
                    ,	{
                            name:	'原料集約（期間指定）'
                        ,	link:	'/totalInquiryMaterialAggregationPeriods'
                        }
                    ,	{
                            name:	'原料集約（週間型）'
                        ,	link:	'/totalInquiryMaterialAggregationWeekly'
                        }
                    ,	{
                            name:	'生産計画（商品別集計）'
                        ,	link:	'/totalInquiryProductionPlanningTotalItems'
                        }
                    ,	{
                            name:	'生産計画（店舗別集計）'
                        ,	link:	'/totalInquiryProductionPlanningTotalStore'
                        }
                    ,	{
                            name:	'生産計画（午前午後比集計）'
                        ,	link:	'/totalInquiryProductionPlanningAmPm'
                        }
                    ]
    }
    ,	{
            name:	'発注内示'
        ,	link:	'/unofficialNoticeOrder'
        }
    ,	{
            name:	'店舗生産計画'
        ,	link:	'/productionPlanning'
        }
    ,	{
            name:	'フェイス表'
        ,	link:	'/face'
        }
    ]
    
,   STORE_MENU: [
        {
            name:	'生産計画'
        ,	link:	'/productionPlanning'
        }
    ,	{	
            name:	'マスター'
        ,	list:	[
                        {
                            name:	'店休日マスター'
                        ,	link:	'/storeMasterStoreHolidays'
                        }
                    ,	{
                            name:	'予算マスター'
                        ,	link:	'/storeMasterBudget'
                        }
                    ,	{
                            name:	'ロス率マスター'
                        ,	link:	'/storeMasterLossRatio'
                        }
                    ,	{
                            name:	'ユーザーマスター'
                        ,	link:	'/masterUsers'
                        }
                    ]
        }
    ,	{
            name:	'フェイス表'
        ,	link:	'/face'
        }
    ]
}

export default MENU_LIST