import axios from '@/axios/axios'
import store from '@/store/index'
import CHEETAH_GRID_CONFIG from '@/const/const.js'

const DISPLAY_OVERLAY_MESSAGE = 'displayOverlayMessage'
const CLEAR_OVERLAY_MESSAGE = 'clearOverlayMessage'
const SEARCH_MESSAGE = 'データ検索中'
const UPDATE_MESSAGE = 'データ更新中'
const DELETE_MESSAGE = 'データ削除中'
const COPY_MESSAGE = 'データコピー中'
const EXCEL_CAPTURE_MESSAGE = 'Excel取込中'
const CSV_CAPTURE_MESSAGE = 'CSV読込中'
const TO_FIXED_ORDER = '定量発注マスター更新中'
const PRINT_PDF = 'PDF出力中'

export default {

    data() {
        return {
            common: {
                dayStringList: ["(月)","(火)","(水)","(木)","(金)","(土)","(日)"],
            },
            screenWidth: screen.width,
            screenHeight: screen.height
        }
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.updateWindowSize)
    },

    mounted() {
        window.addEventListener('resize', this.updateWindowSize)
    },

    computed: {
        isLowResolution_ClientWidth() {
            this.updateWindowSize()
            return this.screenWidth < 1500-35
        },
        isLowResolution() {
            return this.screenWidth < CHEETAH_GRID_CONFIG.DISPLAY_THRESHOLD
        },
        gridRowHeight() {
            return this.isLowResolution ? CHEETAH_GRID_CONFIG.LOW_RESOLUTION_ROW_HEIGHT : CHEETAH_GRID_CONFIG.DEFAULT_ROW_HEIGHT
        },
        gridColWidthReductionRatio() {
            return this.screenWidth / CHEETAH_GRID_CONFIG.DEFAULT_WINDOW_WIDTH
        },
        gridHeightReductionRatio() {
            return this.screenHeight / CHEETAH_GRID_CONFIG.DEFAULT_WINDOW_HEIGHT
        },
        gridTheme() {
            let theme = JSON.parse(JSON.stringify(this.cheetahGridConfig.userTheme))
            theme.font = (this.isLowResolution ? '12px' : '14px') + ' Meiryo UI'
            return theme
        },
        gridStyle() {
            return (default_height) => this.getGridHeight(default_height)
        },
        gridColWidth() {
            return (default_width) => {
                return default_width * this.gridColWidthReductionRatio
            }
        }
    },

    methods: {
        displayIndicator(message) {
            store.dispatch(DISPLAY_OVERLAY_MESSAGE, {message: message})
        },

        displayIndicatorForSearch() {
            this.displayIndicator(SEARCH_MESSAGE)
        },

        displayIndicatorForUpdate() {
            this.displayIndicator(UPDATE_MESSAGE)
        },

        displayOverLay(message) {
            store.dispatch('displayOverlayPattern', {message: message})
        },

        drawerStatus(message) {
            store.dispatch('displayDrawerStatus', {message: message})
        },


        displayIndicatorForDelete() {
            this.displayIndicator(DELETE_MESSAGE)
        },

        displayIndicatorForCopy() {
            this.displayIndicator(COPY_MESSAGE)
        },

        displayIndicatorForExcelCapture() {
            this.displayIndicator(EXCEL_CAPTURE_MESSAGE)
        },

        displayIndicatorForCsvCapture() {
            this.displayIndicator(CSV_CAPTURE_MESSAGE)
        },

        displayIndicatorForToFixedOrder() {
            this.displayIndicator(TO_FIXED_ORDER)
        },

        displayIndicatorForPrintPDF() {
            this.displayIndicator(PRINT_PDF)
        },

        clearIndicator() {
            store.dispatch(CLEAR_OVERLAY_MESSAGE)
        },

        // クエリパラメータの生成処理
        stringifyParams(params) {
            let f = (key, value, array) => {
            if (Array.isArray(value)) {
                for (const element of value) {
                f(key, element, array)
                }
            } else {
                array.push(key + '=' + value)
            }
            return array
            }

            let query = []
            for (const key of Object.keys(params)) {
            f(key, params[key], query)
            }
            return query.join('&')
        },
        // 日曜日判定
        isSunday(yyyymmdd) {
            return this.getDayNumber(yyyymmdd) === 6
        },
        // 土曜日判定
        isSaturday(yyyymmdd) {
            return this.getDayNumber(yyyymmdd) === 5
        },

        // 曜日文字列の取得
        getDayString(yyyymmdd) {
            return this.common.dayStringList[this.getDayNumber(yyyymmdd)]
        },

        // 曜日番号の取得
        getDayNumber(yyyymmdd) {
            let dayNumber = new Date(this.formatDateString(yyyymmdd)).getDay() - 1           
            return dayNumber === -1 ? 6 : dayNumber
        },

        // 年月期フォーマット（yyyymm ⇒ yyyy/mm）
        formatYearMonthString(yyyymm, separator = '/') {
            return [yyyymm.substring(0, 4), yyyymm.substring(4, 6)].join(separator)
        },
        
        // 日付フォーマット（yyyymmdd ⇒ yyyy/mm/dd）
        formatDateString(yyyymmdd) {
            return this.formatDateStringWithSeparator(yyyymmdd)
        },
        // 日付フォーマット
        formatDateStringWithSeparator(yyyymmdd, separator = '/') {
            return [yyyymmdd.substring(0, 4), yyyymmdd.substring(4, 6), yyyymmdd.substring(6, 8)].join(separator)
        },

        // 日付セパレータ（/）の削除
        removeDateSeparator(yyyy_mm_dd, separator = '/') {
            const re = new RegExp(separator, 'g')
            return yyyy_mm_dd.replace(re, '')
        },

        // 日付オブジェクトから日付文字列を取得
        formatDateToString(date, separator = '') {
            return [
                String(date.getFullYear()).padStart(4, '0'), 
                String(date.getMonth() + 1).padStart(2, '0'), 
                String(date.getDate()).padStart(2, '0')
            ].join(separator)
        },

        // タイムスタンプフォーマット
        formatDateToMMDDhhmm(YYYYMMDDhhmmss, notValue = '') {
            if (!YYYYMMDDhhmmss || !YYYYMMDDhhmmss.trim()) {
                return notValue
            }
            return `${YYYYMMDDhhmmss.substring(4, 6)}/${YYYYMMDDhhmmss.substring(6, 8)} ${YYYYMMDDhhmmss.substring(8, 10)}:${YYYYMMDDhhmmss.substring(10, 12)}`
        },

        // カンマ削除
        removeAmountCamma(amount) {
            const re = new RegExp(',', 'g')
            return String(amount).replace(re, '')
        },

        // 日付移動（日付オブジェクト）
        moveDate(date, moveDelta) {
            let movedDate = new Date(date.getTime())
            movedDate.setDate(movedDate.getDate() + moveDelta)
            return movedDate
        },

        // 日付移動（日付文字列）
        moveDateStr(dateStr, moveDelta) {
            let yyyymmdd = this.removeDateSeparator(dateStr) 
            let formatDate = this.formatDateString(yyyymmdd)
            let movedDate = new Date(formatDate)
            return this.formatDateToString(this.moveDate(movedDate, moveDelta))
        },

        getOptionLabel(value, options) {
            const option = options.filter(option => option.value === value).pop()
            return option ? option.label : ''
        },
        
        downloadCsv(document, file_name = null, columns = null, records = null, conditions = null, custom_blob = null) {
            let blob = custom_blob
            if (!blob) {
                if (!file_name || !columns || !records) {
                    return
                }
                blob = this.createCsvBlob(file_name, columns, records, conditions)
            }
            const FILE_EXTENSION = '.csv'
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `${file_name}_${this.getTimestampString()}${FILE_EXTENSION}`
            link.click()
        },

        createCsvBlob(file_name, columns, records, conditions) {
            const CRLF = '\r\n'
            const BOM = '\uFEFF'
            const SEPARATOR = ','
            let content = [BOM + file_name]
            if (conditions) {
                content.push(conditions.map(condition => [condition.label, condition.value].join(SEPARATOR)))
            }
            content.push('')
            content.push(columns.map(column => column.name).join(SEPARATOR))
            content.push(records.map(record => columns.map(column => record[column.key]).join(SEPARATOR)).join(CRLF))

            return new Blob([content.join(CRLF), CRLF])
        },

        // ファイルダウンロード
        downloadFile(document, file_name, blob) {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = file_name
            link.click()
        },

        // Excelインポートエラーファイル生成
        createImportExcelErrorFile(errorList) {
            const rows = errorList.map(error => {
                let columns = []
                if (error.coordinate) {
                    columns.push(error.coordinate)
                }
                if (error.value) {
                    columns.push(error.value.replace(/[\r\n]/, ' '))
                }
                columns.push(error.message)
                return columns.join('\t')
            })
            return new Blob(['\uFEFF', rows.join('\r\n')])
        },

        // タイムスタンプ文字列の取得
        getTimestampString() {
            const now = new Date()
            return now.getFullYear()
            + ('0' + (now.getMonth() + 1)).slice(-2)
            + ('0' + now.getDate()).slice(-2)
            + ('0' + now.getHours()).slice(-2)
            + ('0' + now.getMinutes()).slice(-2)
            + ('0' + now.getSeconds()).slice(-2)
        },

        // ゼロ埋め
        paddingZero(value, digits) {
            if (this.isNaN(value)) {
                return value
            }
            return ('0'.repeat(digits) + value).slice(-1 * digits)
        },

        // 四捨五入
        round(value, digits = 0) {
            if (this.isNaN(value)) {
                return value
            }
            return Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits)
        },
        
        // 数値判定
        isNaN(value) {
            return isNaN(value) || value === '' || value === true || value === false
        },

        // 店舗取得
        getStore(store_code, callback = null) {
            const params = {
                store_code: store_code
            }
            axios.get('/request/common/store/get', {params: params})
            .then((response) => {
                if (callback !== null) {
                callback(response.data.result)
                }
            })
        },

        // 商品取得
        getItem(jan_code, callback = null) {
            const params = {
                jan_code: jan_code
            }
            axios.get('/request/common/item/get', {params: params})
            .then((response) => {
                if (callback !== null) {
                callback(response.data.result)
                }
            })
        },

        getItemFromCallCode(call_code, callback = null) {
            const params = {
                call_code: call_code
            }
            axios.get('/request/common/item/call_code/get', {params: params})
            .then((response) => {
                if (callback !== null) {
                callback(response.data.result)
                }
            })
        },

        // ファイル拡張子の除去
        removeFileExt(file_name) {
            return file_name.replace(/\.[^.]*$/, '')
        },

        async getSystemDate() {
            const response = await axios.get('/request/common/system_date/get')
            return response.data.result.system_date
        },

        async getLeadTime() {
            const response = await axios.get('/request/common/lead_time/get')
            return response.data.result ? response.data.result.system_data * 1 : null
        },

        getGridHeight(default_height, add_px = null) {
            let height = default_height * this.gridHeightReductionRatio - (this.isLowResolution ? 70 : 0) 
            height = height + (this.isLowResolution ? add_px ? add_px : 0 : 0)
            
            //heightは、チーターグリット全体の高さ(ウインドウ内スクロールバーに影響)
            if(this.$route.path == '/materialOrder' || this.$route.path == '/'){
                let clientWidth = document.documentElement.clientWidth
                let clientHeight = document.documentElement.clientHeight
                
                if(clientHeight > 950){
                    if(clientWidth < 1485){
                        
                        height = clientHeight-230
                    }else{
                        height = clientHeight-260
                    }
                }else{
                    if(clientWidth < 1485){
                        height = clientHeight-230
                    }else{
                        height = clientHeight-230
                    }
                }
                
            }
            return {
                'height': `${height}px`
            }
        },

        getGridWidth(default_width) {
            let width = default_width * this.gridColWidthReductionRatio
            if(this.$route.path == '/materialOrder' || this.$route.path == '/'){
                //widthは、チーターグリットの横スクロールバー
                if(this.updateWindowSize() == true){
                    width = document.documentElement.clientWidth+61
                }else{
                    width = document.documentElement.clientWidth
                }
            }
            return {
                'width': `${width}px`
            }
        },

        updateWindowSize() {
            if(this.$route.path == '/materialOrder' || this.$route.path == '/'){
                
                let clientWidth = document.documentElement.clientWidth
                let clientHeight = document.documentElement.clientHeight
                let menuWidth = 0
                //this.screenHeightは、おまじない
                this.screenHeight = clientHeight
                
                //左のメニューが表示されていない場合、その分幅を広げる
                if(this.$store.state.drawer_status == true){
                    menuWidth = 0
                }else{
                    menuWidth = 210
                }
                
                //this.screenWidthは、チーターグリット全体の幅
                
                if(clientWidth < (1274-27)){
                    this.screenWidth = clientWidth-40+170
                    return true
                }else{
                    
                    if(clientWidth < 1485){
                        this.screenWidth = clientWidth-50 + menuWidth
                        return false
                    }else{
                        if(clientWidth > 1514){
                            if(this.$store.state.drawer_status == true){
                                menuWidth = 0
                            }else{
                                menuWidth = 290
                            }
                            this.screenWidth = clientWidth-75 + menuWidth
                        }else{
                            this.screenWidth = clientWidth-50 + menuWidth
                        }
                        
                    }
                    
                    return false
                    
                }

                
            }else{
                this.screenWidth = screen.width
                this.screenHeight = screen.height
            }
        },
        
    }
}