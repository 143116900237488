import Vue from 'vue'
import App from './App.vue'
// import axios from 'axios'
// import VueAxios from 'vue-axios'
// import AxiosPlugin from './plugins/axios'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './assets/style/common.css'
import utils from './utils/utils'

Vue.config.productionTip = false
Vue.mixin(utils)
    
new Vue({
  // axios,
  // VueAxios,
  // AxiosPlugin,
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
