<template>
    <v-btn
    @click="logout"
    color="primary"
    min-width="0"
    class="ma-0 pa-1 text-decoration-underline"
    text
    >
        ログアウト
    </v-btn>
</template>

<script>
export default {
    methods: {
        logout () {
            return this.$store.dispatch('auth_store/logout')
            .then(() => {
                this.$router.push('/login')
            })
            .catch(error => {
                console.log(error)
            })
        },
        showUser () {
            return this.$store.dispatch('user_store/getUser')
        }
    }
}
</script>