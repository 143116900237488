const DISPLAY_THRESHOLD = 1600

const CHEETAH_GRID_CONFIG = {
    DISPLAY_THRESHOLD: DISPLAY_THRESHOLD,
    DEFAULT_WINDOW_WIDTH: 1920,
    DEFAULT_WINDOW_HEIGHT: 1080,
    DEFAULT_ROW_HEIGHT: 29,
    DEFAULT_COL_WIDTH_REDUCTION_RATIO: 1,
    LOW_RESOLUTION_COL_WIDTH_REDUCTION_RATIO: 0.9,
    LOW_RESOLUTION_ROW_HEIGHT: 20,
    
    userTheme: {
        borderColor:"#d3d3d3",
        frozenRowsBgColor: "#f5f5f5",
        highlightBorderColor: "rgb(33,115,70)",
        // selectionBgColor: "#FDA",
        //font:'14px Meiryo UI'
        font: (screen.width < DISPLAY_THRESHOLD ? '12px' : '14px') + ' Meiryo UI'    
    },
    columnId:{
        bgColor:'#f5f5f5',
        textAlign:'center',
    },
    colorLeft:{
        bgColor:'#ccf3ff',
        textAlign:'left',
    },
    colorLeftPriceTray:{
        color:'#EF5350',
        bgColor:'#ccf3ff',
        textAlign:'left',
    },
    colorLeftPriceTrayBgWhite:{
        color:'#EF5350',
        textAlign:'left',
    },
    colorLeftTop:{
        bgColor:'#ccf3ff',
        textAlign:'left',
        textBaseline: "top",
        padding: [5 /*top*/, 0 /*right*/, 0 /*bottom*/, 3 /*left*/] ,
    },
    colorCenter:{
        bgColor:'#ccf3ff',
        textAlign:'center',
    },
    colorCenterTop:{
        bgColor:'#ccf3ff',
        textAlign:'center',
        textBaseline: "top",
        padding: [5 /*top*/, 0 /*right*/, 0 /*bottom*/, 0 /*left*/] ,       
    },
    colorRight:{
        bgColor:'#ccf3ff',
        textAlign:'right',
    },
    colorRightPriceTray:{
        color:'#EF5350',
        bgColor:'#ccf3ff',
        textAlign:'right',
    },
    colorRightPriceTrayBgWhite:{
        color:'#EF5350',
        textAlign:'right',
    },

    colorRightMinus:{
        color:'#EF5350',
        bgColor:'#ccf3ff',
        textAlign:'right',
    },
    colorConfirmed:{
        bgColor:'#f5f5f5',
        textAlign:'right',
    },
    left:{
        textAlign:'left',
    },
    center:{
        textAlign:'center',
    },
    right:{
        textAlign:'right',
    },
    colorCheck:{
        bgColor:'#ccf3ff',
        textAlign:'center',
        checkBgColor:'#1e90ff',
        borderColor:'black'
    },
    check:{
        textAlign:'center',
        checkBgColor:'#1e90ff',
        borderColor:'black'
    },
    errorCenter:{
        bgColor:'#FFCDD2',
        textAlign:'center'
    },
    icon:{
        buttonBgColor: 'transparent' , 
        textAlign: 'center' , 
        padding: '0px'
    },
    colorIcon:{
        buttonBgColor: 'transparent' ,
        bgColor: '#ccf3ff' , 
        textAlign: 'center' , 
        padding: '0px'
    },
    grayOutCheck:{
        bgColor:'#f5f5f5',
        textAlign:'center',
        checkBgColor:'#1e90ff',
        borderColor:'black'
    },
    grayOutCenter:{
        bgColor:'#f5f5f5',
        textAlign:'center',
    },
    grayOutLeft:{
        bgColor:'#f5f5f5',
        textAlign:'left',
    },
}


export default CHEETAH_GRID_CONFIG