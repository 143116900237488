import axios from '@/axios/axios'
import router from '@/router'

const LOGIN = 'LOGIN'
const LOGOUT = 'LOGOUT'
const RETURN_URL = 'RETURN_URL'

const state = () => ({
    token: null,
    authUser: null,
    returnUrl: null
})

const getters = {
    tokenValue(state) {
        return state.token
    },
    returnUrlValue(state) {
        return state.returnUrl ? state.returnUrl : '/'
    }
}

const actions = {
    async login ({ dispatch, commit, getters }, {username, password}) {
        const params = new URLSearchParams()
        params.append('username', username)
        params.append('password', password)

        let config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        return axios.post('/token', params, config)
        .then((response) => {
            commit(LOGIN, {token: response.data.access_token, username: username})
            dispatch('user_store/getUser', null, {root: true})
            router.push(getters.returnUrlValue)
        })
        .catch(error => {
            throw error
        })
    },
    
    async logout ({ dispatch, commit }) {
        commit(LOGOUT)
        dispatch('user_store/clearUser', null, {root: true})
        dispatch('clearStoreCode', null, {root: true})
    },

    async setReturnUrl({ commit }, {returnUrl}) {
        commit(RETURN_URL, {returnUrl: returnUrl})
    }
}

const mutations = {
    [LOGIN] (state, {token, username}) {
        state.token = token
        state.authUser = username
    },
    [LOGOUT] (state) {
        state.token = null
        state.authUser = null
        state.returnUrl = null
    },
    [RETURN_URL] (state, {returnUrl}) {
        state.returnUrl = returnUrl
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}